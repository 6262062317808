import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Наші послуги у футбольному клубі Sport Kids
			</title>
			<meta name={"description"} content={"Розкрий свій потенціал - комплексна підготовка для кожного футболіста-початківця"} />
			<meta property={"og:title"} content={"Наші послуги | Наші послуги у футбольному клубі Sport Kids"} />
			<meta property={"og:description"} content={"Розкрий свій потенціал - комплексна підготовка для кожного футболіста-початківця"} />
			<meta property={"og:image"} content={"https://starepumpas.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://starepumpas.com/img/4050240.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://starepumpas.com/img/4050240.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://starepumpas.com/img/4050240.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://starepumpas.com/img/4050240.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://starepumpas.com/img/4050240.png"} />
			<meta name={"msapplication-TileImage"} content={"https://starepumpas.com/img/4050240.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Наші послуги
			</Text>
			<Text as="p" font="--base" margin="20px 0 20px 0" text-align="center">
				Футбольний клуб Sport Kids пишається тим, що пропонує широкий спектр послуг, спрямованих на зростання та розвиток футбольних талантів. Наші пропозиції спрямовані на підтримку гравців на всіх рівнях їхньої кар'єри, від початківців до професіоналів.
			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Наші послуги
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					- Молодіжні тренувальні програми: Спеціальні тренування для молодих гравців з акцентом на фундаментальні навички, командну роботу та любов до гри.
					<br />
					<br />
					- Підвищення кваліфікації: Спеціалізовані тренування для просунутих гравців, які прагнуть вдосконалити свою техніку, стратегію та інтелект на полі.
					<br />
					<br />
					- Індивідуальні тренування: персоналізована увага для індивідуального вдосконалення навичок, з акцентом на конкретних сферах вдосконалення.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://starepumpas.com/img/5.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://starepumpas.com/img/6.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					- Фітнес і кондиціонування: Комплексні фітнес-програми для підвищення фізичної витривалості, сили та спритності, життєво важливих для розвитку футболіста.
					<br />
					<br />
					- Тренування воротарів: Спеціалізовані тренування для воротарів, що охоплюють усі аспекти цієї унікальної позиції, від відбиття кидків до читання гри.
					<br />
					<br />
					- Літні табори: Сезонні табори, що пропонують інтенсивні тренування, воркшопи та ігри для розвитку навичок і тактичного розуміння.
					<br />
					<br />
					- Пошук та виявлення талантів: Можливість для талановитих гравців бути поміченими скаутами та клубами, що відкриває шлях до професійного футболу.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
						{" \n\t\t\t\t"}У Sport Kids кожен гравець стає на крок ближче до своєї футбольної мрії
					</Text>
					<Button
						href="/contact"
						type="link"
						text-decoration-line="initial"
						background="#3f24d8"
						text-transform="capitalize"
					>
						за додатковою інформацією звертайтеся
					</Button>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://starepumpas.com/img/7.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});